import React from 'react'
import camelcase from 'lodash.camelcase'

import Badge, { BadgeProps, BadgeKindProductLabels, BadgeKindRequestStatuses } from 'components/Badge/Badge'
import { badgeLabels, badgeKinds, allBadgeLabelEnum } from 'types/product-badges'
import { BuyerRequestStatus } from 'types/PricingRequest'

// Old type used with mobx
import { ProductBadge } from 'types/Product'

// New type used with GraphQL
import {
  ProductBadgeEnum,
  PricingRequestStatusEnum,
  RepPricingRequestStatusEnum
} from '../../../__generated__/globalTypes'

export type LabelBadgeProps = {
  id: ProductBadge | ProductBadgeEnum | PricingRequestStatusEnum | RepPricingRequestStatusEnum | BuyerRequestStatus
} & Omit<BadgeProps, 'children' | 'color' | 'kind'>

const LabelBadge = ({ className, id, ...props }: LabelBadgeProps) => {
  const kind = (
    typeof id === 'number' ? badgeKinds[id] : camelcase(id.toString().toLowerCase())
  ) as BadgeKindProductLabels & BadgeKindRequestStatuses
  const label = typeof id === 'number' ? badgeLabels[id] : allBadgeLabelEnum[id]

  return (
    <Badge className={className} kind={kind} {...props}>
      {label}
    </Badge>
  )
}

export default LabelBadge
