import React from 'react'

const Robots = ({ content }: { content: string }) => {
  const isProduction = process.env.NEXT_PUBLIC_DOMAIN === 'fieldfolio.com'
  return <meta name="robots" content={isProduction ? content : 'nofollow, noindex, noarchive'} key="robots" />
}

// INFO: see https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
const StructuredData = ({
  data
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
}) => {
  const json = JSON.stringify(data, null, 2)
  /* eslint-disable-next-line react/no-danger */
  return <script id="structured-data" dangerouslySetInnerHTML={{ __html: json }} type="application/ld+json" />
}

export { Robots, StructuredData }
