import { DateTime } from 'luxon'

import Badge, { BadgeProps } from 'components/Badge/Badge'
import { ProductBadgeEnum } from '../../../../../../__generated__/globalTypes'

import styles from './StockStatusBadge.module.css'

type StockBadgeProps = {
  showIcon?: boolean
} & Omit<BadgeProps, 'children'>

type StockDueBadgeProps = {
  stockDueDate: DateTime | null
} & StockBadgeProps

export const StockDueBadge = ({ stockDueDate, showIcon, ...rest }: StockDueBadgeProps) => {
  if (!stockDueDate) return null

  return (
    <Badge
      className={styles.badge}
      kind="brand"
      style="tinted"
      leadingIcon={showIcon ? 'clock' : undefined}
      leadingIconColor="var(--colorOrange)"
      {...rest}>
      Stock Due {stockDueDate.toFormat('dd LLL y')}
    </Badge>
  )
}

const InStockBadge = ({ showIcon, ...rest }: StockBadgeProps) => (
  <Badge
    className={styles.badge}
    kind="brand"
    style="tinted"
    leadingIcon={showIcon ? 'check-circle' : undefined}
    leadingIconColor="var(--colorGold)"
    {...rest}>
    In Stock
  </Badge>
)

const OutOfStockBadge = ({ showIcon, ...rest }: StockBadgeProps) => (
  <Badge
    className={styles.badge}
    style="tinted"
    leadingIcon={showIcon ? 'x-circle' : undefined}
    leadingIconColor="var(--colorRed8)"
    {...rest}>
    Out of Stock
  </Badge>
)

type StockStatusBadgeProps = {
  inStock: boolean
  stockDueDate?: DateTime | null
  stockOnHand?: number | null
  allowBackorders?: boolean
  badge: ProductBadgeEnum | null
  showIcon?: boolean
} & Omit<BadgeProps, 'children'>

export const StockStatusBadge = ({
  showIcon,
  inStock,
  allowBackorders,
  stockDueDate,
  stockOnHand,
  badge,
  ...rest
}: StockStatusBadgeProps) => {
  // These badges are considered 'unavailable', meaning we should show 'stock due' badge if it makes sense
  const isUnavailableBadge = badge === ProductBadgeEnum.UPCOMING || badge === ProductBadgeEnum.SOLD_OUT

  const showStockDueBadge =
    stockDueDate && ((inStock && isUnavailableBadge) || !inStock || stockOnHand == null || stockOnHand <= 0)

  const showInStockBadge = inStock && (!allowBackorders || !isUnavailableBadge)

  const showOutOfStockBadge = !inStock && !allowBackorders

  if (showStockDueBadge) {
    return <StockDueBadge stockDueDate={stockDueDate} showIcon={showIcon} {...rest} />
  }

  if (showInStockBadge) {
    return <InStockBadge showIcon={showIcon} {...rest} />
  }

  if (showOutOfStockBadge) {
    return <OutOfStockBadge showIcon={showIcon} {...rest} />
  }

  return null
}

export default StockStatusBadge
