import { useCallback, useMemo } from 'react'
import classnames from 'classnames'
import qs, { ParsedQs } from 'qs'
import classNames from 'classnames/bind'
import ButtonLink from 'components/ButtonLink'
import { ButtonProps } from 'components/Button/Button'

import styles from './Pagination.module.css'

const cx = classNames.bind(styles)

export type PaginationProps = {
  thingName: string
  currentPage: number
  totalPages: number
  totalItems: number
  perPage: number
  showSummary?: boolean
  className?: string
  baseUrl?: string
  origin?: string
  siblingCount?: number
  showPageStatus?: boolean
  kind?: 'default' | 'primary'
  buttonKind?: ButtonProps['kind']
  customParams?: Record<string, string | string[] | undefined | null | boolean>
}

const DOTS = '...'

const Pagination = ({
  thingName,
  currentPage,
  totalPages,
  totalItems,
  perPage,
  showSummary = true,
  className,
  baseUrl = document.location.href, // explicity pass this from next router for SSR
  origin = document.location.origin, // explicity pass this from next router for SSR
  siblingCount = 4,
  showPageStatus = false,
  kind = 'default',
  buttonKind = 'link',
  customParams
}: PaginationProps) => {
  const linkUrl = useCallback(
    (page: number) => {
      const url = new URL(baseUrl, origin)
      const parts = qs.parse(url.search.replace('?', ''))
      parts['page'] = page.toString()
      const params: Record<string, string | string[] | undefined | ParsedQs | ParsedQs[] | null | boolean> = {
        ...parts,
        ...customParams
      }

      for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
          params[key] = value.join(',')
        } else {
          params[key] = value
        }
        if (!params[key]) {
          delete params[key]
        }
      }

      // will assist with caching if we don't redundantly specify the page
      if (params['page'] === '1') {
        delete params.page
      }

      return `${url.origin}${url.pathname}?${qs.stringify(params)}`.replace(/\?$/, '')
    },
    [baseUrl, customParams, origin]
  )

  const buttonLinkComponent = (page: number) => {
    return (
      <ButtonLink
        key={page}
        kind={buttonKind}
        className={cx(styles.link, [kind])}
        disabled={page === currentPage}
        href={linkUrl(page)}
        label={page.toString()}
      />
    )
  }

  const linkContent = (start: number, end: number) => {
    const content = []

    for (let i = start; i <= end; i++) {
      content.push(buttonLinkComponent(i))
    }
    return content
  }

  const usePagination = (totalPages: number, siblingCount: number, currentPage: number) => {
    const paginationRange = useMemo(() => {
      const leftSiblingIndex = Math.max(1, currentPage - siblingCount)
      const rightSiblingIndex = Math.min(totalPages, currentPage + siblingCount)

      const showLeftDots = leftSiblingIndex > 3 && !(currentPage - 7 === 1)
      const showRightDots = rightSiblingIndex < totalPages - 3

      const firstPageIndex = 1
      const secondPageIndex = 2
      const secondToTheLastPageindex = totalPages - 1
      const lastPageIndex = totalPages

      // Logic 1: Return all range of pages
      if (!showLeftDots && !showRightDots) {
        return linkContent(firstPageIndex, lastPageIndex)
      }
      // Logic 2: Total pages greater than page buttons, show only left dots
      if (showLeftDots && !showRightDots) {
        const rightRange = linkContent(currentPage - 4, lastPageIndex)
        return [buttonLinkComponent(firstPageIndex), buttonLinkComponent(secondPageIndex), DOTS, ...rightRange]
      }
      // Logic 3: Total pages greater than page buttons, show only right dots
      if (!showLeftDots && showRightDots) {
        const leftRange = linkContent(1, currentPage + 4)
        return [...leftRange, DOTS, buttonLinkComponent(secondToTheLastPageindex), buttonLinkComponent(lastPageIndex)]
      }
      // Logic 4: Total pages greater than page buttons, show left & right dots
      if (showLeftDots && showRightDots) {
        const middleRange = linkContent(leftSiblingIndex, rightSiblingIndex)
        return [
          buttonLinkComponent(firstPageIndex),
          buttonLinkComponent(secondPageIndex),
          DOTS,
          ...middleRange,
          DOTS,
          buttonLinkComponent(secondToTheLastPageindex),
          buttonLinkComponent(lastPageIndex)
        ]
      }
    }, [siblingCount, currentPage, totalPages])

    return paginationRange
  }

  const paginationContent = usePagination(totalPages, siblingCount, currentPage)

  // No pagination for single page
  if (totalItems === 0 || totalPages <= 1) {
    return null
  }

  const formatNumber = new Intl.NumberFormat()

  return (
    <div className={classnames(styles.paginationWrapper, { [styles.showSummary]: showSummary }, className)}>
      {showSummary && (
        <>
          <div className={styles.summary}>
            Showing {formatNumber.format(perPage * (currentPage - 1) + 1)} to{' '}
            {totalItems < perPage * currentPage
              ? formatNumber.format(totalItems)
              : formatNumber.format(perPage * currentPage)}{' '}
            of {formatNumber.format(totalItems)} {thingName}
          </div>
          <div className={styles.summaryMobileView}>
            Page {formatNumber.format(currentPage)} of {formatNumber.format(totalPages)}
          </div>
        </>
      )}

      <div className={styles.pages}>
        <ButtonLink
          kind={buttonKind}
          icon="arrow-left"
          iconSize={16}
          className={cx(styles.arrowPrev, [kind])}
          size="mini"
          disabled={currentPage === 1}
          href={linkUrl(currentPage - 1)}
          label={''}
        />

        {paginationContent}

        {currentPage + 1 <= totalPages && (
          <ButtonLink
            kind={buttonKind}
            icon="arrow-right"
            iconSize={16}
            className={cx(styles.arrowNext, [kind])}
            size="mini"
            disabled={currentPage === totalPages}
            href={linkUrl(currentPage + 1)}
            label={''}
          />
        )}
      </div>

      {showPageStatus && (
        <div className={cx(styles.showPageStatus, [kind])}>
          Page {formatNumber.format(currentPage)} of {formatNumber.format(totalPages)}
        </div>
      )}
    </div>
  )
}

export default Pagination
