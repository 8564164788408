import {
  ProductBadgeEnum,
  PricingRequestStatusEnum,
  RepPricingRequestStatusEnum
} from '../../__generated__/globalTypes'

// Old
export enum badgeLabels {
  'undefined',
  'New',
  'Best Seller',
  'Special',
  'Upcoming',
  'Sold Out',
  'Discontinued'
}
export enum badgeKinds {
  'undefined',
  'new',
  'bestSeller',
  'special',
  'upcoming',
  'soldOut',
  'discontinued'
}

// New
export const productBadgeLabelFromEnum = {
  [ProductBadgeEnum.NEW]: 'New',
  [ProductBadgeEnum.BEST_SELLER]: 'Best Seller',
  [ProductBadgeEnum.SPECIAL]: 'Special',
  [ProductBadgeEnum.UPCOMING]: 'Upcoming',
  [ProductBadgeEnum.SOLD_OUT]: 'Sold Out',
  [ProductBadgeEnum.DISCONTINUED]: 'Discontinued'
}
export const productBadgeOptions = Object.entries(productBadgeLabelFromEnum).map(b => {
  return { value: b[0], label: b[1] }
})

export const requestStatusLabelFromEnum = {
  [PricingRequestStatusEnum.APPROVED]: 'Approved',
  [PricingRequestStatusEnum.REQUESTED]: 'Requested',
  [PricingRequestStatusEnum.REJECTED]: 'Rejected',
  ['PENDING']: 'Pending',
  ['DECLINED']: 'Declined'
}

export const repRequestStatusLabelFromEnum = {
  [RepPricingRequestStatusEnum.APPROVED]: 'Approved',
  [RepPricingRequestStatusEnum.REQUESTED]: 'Requested',
  [RepPricingRequestStatusEnum.REJECTED]: 'Rejected',
  [RepPricingRequestStatusEnum.REMOVED]: 'Removed'
}

export const allBadgeLabelEnum = {
  ...productBadgeLabelFromEnum,
  ...requestStatusLabelFromEnum,
  ...repRequestStatusLabelFromEnum
}
