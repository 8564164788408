import React, { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import classNames from 'classnames/bind'
import { DateTime } from 'luxon'

import { useUserSession } from 'hooks'
import UserContentImage from 'components/UserContentImage'
import LabelBadge from 'components/LabelBadge'
import AddToCartButton from 'modules/buyer-hub/checkout/components/AddToCartButton'
import StockStatusBadge from 'modules/marketplace/product/components/StockStatusBadge'

import { ProductItemFragment } from 'modules/marketplace/common/components/ProductItem/graphql/__generated__/ProductItemFragment'
import { IOrderLineItem } from 'modules/buyer-hub/checkout/components/AddToCartButton/AddToCartButton'
import { ProductBadgeEnum } from '../../../../../../__generated__/globalTypes'

import styles from './ProductItem.module.css'

const cx = classNames.bind(styles)

// Just define the types that this component needs. Use interface so that it can be extended
export interface ISeller {
  id: string
  /**
   * Path to the seller on Marketplace
   */
  marketplacePath: string | null
  /**
   * Shorter business name, to be used on Marketplace
   */
  displayName: string | null
}

export interface ICatalog {
  id: string
  /**
   * Path to the catalog on Marketplace
   */
  marketplacePath: string | null
  /**
   * Name of the catalog
   */
  name: string | null
  /**
   * Currency symbol, e.g. '$'
   */
  currencySymbol: string | null
  /**
   * 3-letter currency code, e.g AUD
   */
  currencyCode: string | null
  /**
   * Can backorders be created in marketplaceo
   */
  createBackorders: boolean
}

type ProductProps = {
  seller: ISeller
  catalog: ICatalog
  product: ProductItemFragment
  showPrices?: boolean
  showAddToCart?: boolean
  hasSellerName?: boolean
  hasCatalogName?: boolean
  isBuyerBanned?: boolean
  isPreview?: boolean
}

const ProductItem = ({
  seller,
  catalog,
  product,
  showPrices = true,
  showAddToCart = true,
  hasSellerName = true,
  hasCatalogName = true,
  isBuyerBanned = false,
  isPreview = false
}: ProductProps) => {
  const router = useRouter()
  const [orderLineItem, setOrderLineItem] = useState<IOrderLineItem | undefined | null>(undefined)

  const { isBuyer } = useUserSession()
  const productImageUrl = product.firstPicture?.gridPhotoUrl
  const firstVariety = product.firstVariety

  const isStack = product.isStack
  const inStock = !product.outOfStock
  const stockOnHand = firstVariety?.stockOnHand
  const badge = product.badge
  const allowBackorders = catalog.createBackorders

  // Show 'out-of-stock' in preference to 'sold-out' badge. i.e. no point showing both together, would be confusing
  const stockDueDate = product.stockDueDate ? DateTime.fromISO(product.stockDueDate) : null
  const showOutOfStockBadge = (!inStock || stockDueDate) && !isStack
  const showProductBadge = badge && (!(showOutOfStockBadge && badge === ProductBadgeEnum.SOLD_OUT) || stockDueDate)

  const hasSalePrice: boolean = firstVariety != null && !!firstVariety?.salePrice
  const displayPrice: number = hasSalePrice ? firstVariety?.salePrice || 0 : product.prices[0]
  const hasPricing: boolean = product.prices.length > 0

  const isOrderItem = orderLineItem != null

  return (
    <div className={styles.container}>
      <div className={styles.imageSquare}>
        <Link href={`${product.marketplacePath || '/'}${isPreview ? '?preview=1' : ''}`}>
          <a className={cx(styles.imageWrapper, { isOrderItem })}>
            <UserContentImage src={productImageUrl} alt={product.name} />
          </a>
        </Link>

        {showProductBadge && <LabelBadge className={styles.productBadge} id={badge} />}
        {showOutOfStockBadge && (
          <StockStatusBadge
            inStock={inStock}
            allowBackorders={allowBackorders}
            stockDueDate={stockDueDate}
            stockOnHand={stockOnHand}
            className={styles.outOfStockBadge}
            badge={badge}
            showIcon
          />
        )}
      </div>

      {isBuyer &&
        !isBuyerBanned &&
        hasPricing &&
        showAddToCart &&
        firstVariety != null &&
        (inStock || allowBackorders) && (
          <div className={styles.addToCartWrapper}>
            <AddToCartButton
              isStack={isStack}
              handleStackClick={() => router.push(`${product.marketplacePath || '/'}${isPreview ? '?preview=1' : ''}`)}
              sellerId={seller.id}
              catalogId={catalog.id}
              productId={product.id}
              variety={firstVariety}
              onLineItemChange={setOrderLineItem}
            />
          </div>
        )}

      <div className={styles.productDetails}>
        <div className={styles.productName}>
          <Link href={`${product.marketplacePath || '/'}${isPreview ? '?preview=1' : ''}`}>
            <a>{product.name}</a>
          </Link>
        </div>

        {showPrices && product.prices.length > 0 && (
          <div className={styles.priceContainer}>
            {(isStack || product.prices.length > 1) && <span className={styles.from}>From</span>}
            <span className={cx(styles.price, { hasSalePrice })}>
              {catalog.currencySymbol}
              {displayPrice.toFixed(2)}
            </span>
            <span className={styles.currencyCode}>{catalog.currencyCode}</span>
            {!isStack && <span className={styles.unit}>{firstVariety?.unit}</span>}
          </div>
        )}

        {(hasCatalogName || hasSellerName) && (
          <div className={styles.catalogMeta}>
            {hasCatalogName && (
              <div className={styles.productCatalogName}>
                <Link href={catalog.marketplacePath || '/'}>
                  <a>{catalog.name}</a>
                </Link>
              </div>
            )}

            {hasSellerName && (
              <div className={styles.productSellerDisplayName}>
                <Link href={seller.marketplacePath || ''}>
                  <a>{seller.displayName}</a>
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductItem
